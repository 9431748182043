import Vue from "vue";

import {
  env
} from "./IYSEnvironment";
import CustomError from "./errors";
import Swal from "sweetalert2";

const GenericErrorAlert = async () =>
  await Swal.fire({
    title: "Bir hata oluştu!\nLütfen daha sonra tekrar deneyiniz.",
    icon: "error",
    confirmButtonText: "Kapat",
    width: "24rem"
  });

const Alert = async (msg, type = "warning") => {
  await Swal.fire({
    title: msg,
    icon: type,
    confirmButtonText: "Kapat",
    width: "24rem"
  });
};

/**
 * A central vuex action caller. Displays loading bar and writes some logs in dev mode.
 * @param {String} action_name vuex action name e.g.: get_new_captcha
 * @param {Function} action vuex action e.g.:({ }, payload) => BFF().post("/captcha/generate" + (DEV ? "?debug=1&pretty=1" : ""), payload),
 * @param {Object} options {loading: true}
 * @returns {Promise|Error}
 */
const actionCall = async (action_name, action, payload, options) => {
  let loading = options.loading ? Vue.$loading.show() : {
    hide: () => null
  };
  // let section = payload ? (typeof payload.section ? payload.section : "") : "";
  // let appNo = payload ? (typeof payload.appNo ? payload.appNo : "") : "";
  try {
    env.CONSOLE.log(`>> ${action_name.toUpperCase()} (API-CALL)`, payload);

    const response = await action;

    loading.hide();

    env.CONSOLE.log(
      `<< ${action_name.toUpperCase()} (API-RESULT)`,
      response.data
    );


    if (response.data.ok) {
      // section
      //   ? _paq.push(["trackEvent", "SUCCESS", action_name, section])
      //   : _paq.push(["trackEvent", "SUCCESS", action_name]);
      return response.data;
    }

    throw new CustomError(response.data, response.data.error.summary);
  } catch (error) {
    loading.hide();

    // section
    //   ? _paq.push(["trackEvent", "ERROR", action_name, section])
    //   : _paq.push(["trackEvent", "ERROR", action_name]);

    if (error.response) {
      console.error("HATA KODU:", error.response.status);
      if (error.response.status === 413) {
        let message = "Yüklemeye çalıştığınız dosya izin verilen dosya boyutundan büyük, lütfen kontrol ederek tekrar yükleyiniz."
        await Alert(message);
        throw new Error(message);
      }
      if (error.response.status === 429) {
        let message = error.response.data.message || "İstek limitini aştınız. Belirli bir süre bekledikten sonra tekrar deneyiniz."
        await Alert(message);
        throw new Error(message);
      }
    }

    if (action_name === "get_new_captcha") {
      throw new Error("Bir hata oluştu! Lütfen daha sonra tekrar deneyiniz.");
    }

    if (error instanceof CustomError) {
      env.CONSOLE.warn(`<< ${action_name.toUpperCase()} (API-ERROR)`, error);

      let cerror = error.res.error;
      if (cerror.type === "iys.io/error/validation") {
        if (cerror.fields.length > 0) {
          await Alert(cerror.fields[0].reason);
        }
      } else {
        await Alert(error.res.error.summary);
      }

      /**
       * Burada Componentlara sadece {ok:false, error:{...}} olarak gelen responsun errorunu yolluyorum.
       * TODO: Bu yaklaşımın uygunluğunu test et.
       */
      throw error.res.error;
    } else {
      env.CONSOLE.error(`<< ${action_name.toUpperCase()} (API-ERROR)`, error);

      await GenericErrorAlert();

      throw new Error("Bilinmeyen bir hata oluştu");
    }
  }
};

/**
 * A wrapper for a vuex action. Injects action_name and other options to a action call
 * @param {*} action
 * @param {*} m_options
 * @returns {Object} a vuex action definition with actionCall injected in the action.
 */
const apiAction = (action, m_options) => {
  let action_name = Object.keys(action)[0];
  let options = {
    loading: true,
    ...m_options
  };
  return {
    //a: {state, ...}, b: payload
    [action_name]: (a, b) =>
      actionCall(action_name, action[action_name](a, b), b, options)
  };
};

export default actions => {
  Object.keys(actions).forEach(key => {
    actions = {
      ...actions,
      ...apiAction({
        [key]: actions[key]
      })
    };
  });
  return actions;
};

// actions = {
//   ...actions,
//   ...apiAction({ get_new_captcha: actions.get_new_captcha }, {loading:false})
// };

// const IYSActions = {};

// IYSActions.install = (Vue, opt) =>{
//   console.log("BB:",  Vue.$loading, Vue.$env, opt.vm.$store)
// }

// export default IYSActions;
