/**
 * Plugin for global mixins and directives
 */

import Swal from "sweetalert2";

const IYSMain = {
  install(Vue, options) {
    const Toast = Swal.mixin({
      toast: true,
      icon: "success",
      position: "top",
      // position: 'top-end',
      showConfirmButton: false,
      timer: 5000
    });

    Vue.mixin({
      filters: {
        ddmmyyyy(val) {
          const date = new Date(val);
          const mm = date.getMonth() + 1; // getMonth() is zero-based
          const dd = date.getDate();

          return [(dd > 9 ? "" : "0") + dd, (mm > 9 ? "" : "0") + mm, date.getFullYear()].join("/");
        },

        toIntWithComma(val) {
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        toIntWithDot(val) {
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },

        toFileName(n, len = 25) {
          var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
          var filename = n.replace("." + ext, "");
          if (filename.length <= len) {
            return n;
          }
          filename = filename.substr(0, len) + (n.length > len ? "[...]" : "");
          return filename + "." + ext;
        },

        toUpperCase(val) {
          return val.toUpperCase("tr");
        },

        toKKNo(val) {
          return val.split("-").join(" ");
        },

        tovergiNo(txt) {
          if (!txt) return "";
          return `${txt.substring(0, 3)} ${txt.substring(3, 6)} ${txt.substring(
            6,
            8
          )} ${txt.substring(8, 11)}`;
        },

        tomersisNo(txt) {
          if (!txt) return "";
          return `${txt.substring(0, 4)} ${txt.substring(4, 8)} ${txt.substring(
            8,
            12
          )} ${txt.substring(12, 16)}`;
        },

        tocontactTcKimlikNo(txt) {
          if (!txt) return "";
          return `${txt.substring(0, 3)} ${txt.substring(3, 6)} ${txt.substring(
            6,
            9
          )} ${txt.substring(9, 11)}`;
        },

        tocontactCellPhone(txt) {
          if (!txt) return "";
          let s = txt;
          if (s.charAt(0) === "9") s = s.substring(1);
          return `(${s.substring(0, 4)}) ${s.substring(4, 7)} ${s.substring(
            7,
            9
          )} ${s.substring(9, 11)}`;
        }
      },

      methods: {
        Alert(msg, type = "warning") {
          return Swal.fire({
            title: msg,
            icon: type,
            confirmButtonText: "Kapat",
            width: "24rem"
          });
        },

        ConnectionError() {
          return Swal.fire({
            title: "Bir hata oluştu!\nLütfen öncelikle internet bağlantınızı kontrol ediniz.",
            icon: "error",
            confirmButtonText: "Kapat",
            width: "24rem"
          });
        },

        Toast(msg) {
          return Toast.fire({
            icon: "success",
            title: msg,
            position: "top"
          });
        },

        clearValidationErrorDisplays() {
          for (const key in {
              ...this.problem
            }) {
            if (!this.problem.hasOwnProperty(key)) continue;
            this.problem[key] = "";
          }
        },

        HandleValidationError(cerror) {
          // if (cerror.type.substring(3) === ".io/err/validation") {
          //   cerror.fields.forEach(field => {
          //     this.problem[field.param] = field.msg;
          //   });
          // }

          if (typeof cerror.type !== "undefined") {
            // if (
            //   cerror.type.substring(3) === ".io/err/validation" ||
            //   cerror.type.substring(3) === ".io/error/validation"
            // )
            if (cerror.type === "iys.io/error/validation") {
              cerror.fields.forEach(field => {
                this.problem[field.name] = field.reason;
              });
            }
          } else {
            // console.warn("HATA:", cerror);
            console.warn("HATA:", cerror.message);
          }
        }
      },

      computed: {
        isMobile() {
          return this.$env.MOBILE
        },
        loggedIn() {
          return this.$keycloak ?
            this.$keycloak.authenticated ?
            true :
            false :
            false;
        },
        tckn_keycloak() {
          return this.loggedIn ? this.$keycloak.tokenParsed.preferred_username : "";
        },
      }
    });

    Vue.directive("uppercase", {
      update(el) {
        el.value = el.value.toLocaleUpperCase("tr-TR");
      }
    });
  }
};

export default IYSMain;
