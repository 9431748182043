module.exports = [
  {
    path: "/a/:access_code",
    component: () => import("@/layouts/MyLayout"),
    children: [
      {
        path: "",
        component: () => import("@/pages/IYS"),
        meta: { siteMapHide: true, toNotFound: true },
        children: [
          {
            path: "",
            component: () => import("@/components/sayfalar/Aydinlatma"),
            meta: {
              title: "İleti Yönetim Sistemi A.Ş. Yönetim Beyanı",
              aydinlatma : true
            }
          },
        ]
      },
    ]
  },
  {
    path: "",
    component: () => import("@/layouts/MyLayout"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/pages/Home.vue")
      },

      {
        path: "api-sandbox-erisim",
        component: () => import("@/components/sayfalar/iys/ApiErisim"),
        meta: {
          title: "İleti Yönetim Sistemi A.Ş. Api Sandbox Erişim"
        }
      },

      {
        path: "hizmetlerimiz",
        component: () => import("@/pages/HS"),
        meta: { siteMapHide: true, toNotFound: true },
        children: [
          {
            path: "",
            component: () => import("@/components/sayfalar/hs/Nasil"),
            meta: {
              title: "Hizmetlerimiz"
            }
          }
        ]
      },

      {
        path: "hizmet-saglayici/",
        component: () => import("@/pages/HS"),
        meta: { siteMapHide: true, toNotFound: true },
        children: [
          {
            path: "temel-hizmetler",
            component: () => import("@/components/sayfalar/hs/TemelHizmetler"),
            meta: {
              title: "Temel Hizmetler"
            }
          },
          {
            path: "kurumsal-hizmetler",
            component: () => import("@/components/sayfalar/hs/KurumsalHizmetler"),
            meta: {
              title: "Kurumsal Hizmetler"
            }
          },
          {
            path: "araci-hizmet-saglayicilar",
            component: () => import("@/components/sayfalar/hs/AraciHizmetSaglayicilar"),
            meta: {
              title: "Aracı Hizmet Sağlayıcılar"
            }
          }
        ]
      },

      {
        path: "vatandas-hizmetleri",
        component: () => import("@/pages/Vatandaslar"),
        meta: { siteMapHide: true, toNotFound: true },
        children: [
          {
            path: "",
            component: () => import("@/components/sayfalar/vatandaslar/Nasil"),
            meta: {
              title: "Vatandaşlar"
            }
          }
        ]
      },
      {
        path: "is-ortaklari",
        component: () => import("@/pages/IsOrtakligi"),
        meta: { siteMapHide: true, toNotFound: true },
        children: [
          {
            path: "",
            component: () => import("@/components/sayfalar/IsOrtakligi"),
            meta: {
              title: "İş Ortaklığı"
            }
          }
        ]
      },

      // {
      //   path: 'tanitim',
      //   components: {
      //     default: () => import('@/pages/Home.vue'),
      //     modal: () => import('@/components/genel/Tanitim')
      //   }
      // },

      {
        path: "hizmet-saglayici",
        component: () => import("@/pages/HS"),
        meta: { siteMapHide: true, toNotFound: true },
        children: [
          {
            path: "basvuru/nasil-yapilir",
            meta: { hide_basvuru: true, reset_basvuru: true },
            component: () =>
              import("@/components/hs/basvuru/Basvuru_Nasil_Yapilir")
          },
          {
            path: "basvuru/yeni-giris",
            meta: { hide_basvuru: true, reset_basvuru: true },
            component: () =>
              import("@/components/hs/basvuru/Basvuru_Yeni_Giris")
          },
          {
            path: "basvuru/devam-giris",
            meta: { hide_basvuru: true, reset_basvuru: true },
            component: () =>
              import("@/components/hs/basvuru/Basvuru_Devam_Giris")
          },
          {
            path: "basvuru/mersis-tckn",
            meta: {
              hide_basvuru: true,
              requires_appNo: true,
              requires_RESERVED: true,
              siteMapHide: true
            },
            component: () => import("@/components/hs/basvuru/ZMesisTcknSorgula")
          },
          {
            path: "basvuru/form",
            meta: {
              hide_basvuru: true,
              requires_appNo: true,
              requires_RESERVED: true,
              siteMapHide: true
            },
            component: () => import("@/components/hs/basvuru/ZForm")
          },
          {
            path: "basvuru/belge-yukleme-on-basvuru",
            meta: {
              hide_basvuru: true,
              requires_appNo: true,
              requires_RESERVED: true,
              siteMapHide: true
            },
            component: () => import("@/components/hs/basvuru/ZBelgeYukleme")
          },
          {
            path: "basvuru/marka-tanimlama-on-basvuru",
            meta: {
              hide_basvuru: true,
              requires_appNo: true,
              requires_RESERVED: true,
              siteMapHide: true
            },
            component: () => import("@/components/hs/basvuru/ZMarkaTanimlama")
          },
          {
            path: "basvuru/onaylama-on-basvuru",
            meta: {
              hide_basvuru: true,
              requires_appNo: true,
              requires_RESERVED: true,
              siteMapHide: true
            },
            component: () => import("@/components/hs/basvuru/ZOnaylama")
          },
          {
            path: "basvuru/sonuc-on-basvuru",
            meta: {
              hide_basvuru: true,
              requires_appNo: true,
              siteMapHide: true,
              // requires_INITIAL : true
            },
            component: () => import("@/components/hs/basvuru/ZSonuc")
          },
          {
            path: "basvuru/sonuc-detay-on-basvuru",
            meta: {
              hide_basvuru: true,
              requires_appNo: true,
              siteMapHide: true,
              // requires_INITIAL : true
            },
            component: () => import("@/components/hs/basvuru/ZSonucDetay")
          },
          {
            path: "basvuru/bilgi-girisi",
            meta: {
              hide_basvuru: true,
              requires_appNo: true,
              // requires_INITIAL_AGENT_CONFIRMED : true,
              siteMapHide: true
            },
            component: () => import("@/components/hs/basvuru/BilgiGirisi")
          },
          {
            path: "basvuru/onaylama",
            meta: {
              hide_basvuru: true,
              requires_appNo: true,
              // requires_INITIAL_AGENT_CONFIRMED : true,
              siteMapHide: true
            },
            component: () => import("@/components/hs/basvuru/Onaylama")
          },
          {
            path: "basvuru/sonuc",
            meta: {
              hide_basvuru: true,
              requires_appNo: true,
              siteMapHide: true
            },
            component: () => import("@/components/hs/basvuru/Sonuc")
          },
          {
            path: "basvuru/sonuc-detay",
            meta: {
              hide_basvuru: true,
              requires_appNo: true,
              siteMapHide: true
            },
            component: () => import("@/components/hs/basvuru/SonucDetay")
          }
          // {
          //   path: "odeme/odeme",
          //   component: () => import("@/components/hs/odeme/Odeme")
          // },
          // {
          //   path: "odeme/sonuc-havale",
          //   component: () => import("@/components/hs/odeme/SonucHavale")
          // },
          // {
          //   path: "odeme/sonuc-kredikarti",
          //   component: () => import("@/components/hs/odeme/SonucKK")
          // },
          // {
          //   path: "odeme/onay-ucretsiz",
          //   component: () => import("@/components/hs/odeme/OnayUcretsiz")
          // },
          // {
          //   path: "odeme/sonuc-ucretsiz",
          //   component: () => import("@/components/hs/odeme/SonucUcretsiz")
          // },
          // {
          //   path: "odeme/onay-baslangic",
          //   component: () => import("@/components/hs/odeme/OnayBaslangic")
          // },
          // {
          //   path: "odeme/sonuc-baslangic",
          //   component: () => import("@/components/hs/odeme/SonucBaslangic")
          // },
          // {
          //   path: "odeme/onay-enterprise",
          //   component: () => import("@/components/hs/odeme/OnayEnterprise")
          // },
          // {
          //   path: "odeme/sonuc-enterprise",
          //   component: () => import("@/components/hs/odeme/SonucEnterprise")
          // }
        ]
      },

      {
        path: "iys",
        component: () => import("@/pages/IYS"),
        meta: { siteMapHide: true, toNotFound: true },
        children: [
          {
            path: "nedir",
            component: () => import("@/components/sayfalar/iys/Nedir"),
            meta: {
              title: "Nedir"
            }
          },
          {
            path: "nasil-calisir",
            component: () => import("@/components/sayfalar/iys/NasilCalisir"),
            meta: {
              title: "Nasıl Çalışır"
            }
          },
          {
            path: "kanun",
            component: () => import("@/components/sayfalar/iys/Kanun"),
            meta: {
              title: "Kanun"
            }
          },
          {
            path: "yonetmelik", //eski-yonetmelik
            component: () => import("@/components/sayfalar/iys/EskiYonetmelik"),
            meta: {
              title: "Yönetmelik"
            }
          },
          {
            path: "yonetmelik-degisiklik", //yeni-yonetmelik
            component: () => import("@/components/sayfalar/iys/Yonetmelik"),
            meta: {
              title: "Yönetmelikte Değişiklik"
            }
          },
          // {
          //   path: "tanimlar",
          //   component: () => import("@/components/sayfalar/iys/Tanimlar"),
          //   meta: {
          //     title: "Tanımlar"
          //   }
          // },
          {
            path: "aydinlatma-metni",
            component: () => import("@/components/sayfalar/iys/AydinlatmaMetni"),
            meta: {
              title: "Aydınlatma Metni"
            }
          },
          {
            path: "kvkk-ve-gizlilik",
            component: () => import("@/components/sayfalar/iys/KvkVeGizlilik"),
            meta: {
              title: "KVKK ve Gizlilik Politikası"
            }
          },
          {
            path: "cerez-ve-izleme-araci-politikasi",
            component: () => import("@/components/sayfalar/iys/CerezPolitikasi"),
            meta: {
              title: "Çerez ve İzleme Aracı Politikası"
            }
          },
          {
            path: "is-ortagi-bilgi-guvenligi",
            component: () => import("@/components/sayfalar/iys/IoBilgiGuvenligi"),
            meta: {
              title: "İş Ortakları Bilgi Güvenliği Bilgilendirme Metni"
            }
          },
          {
            path: "api-lisans-kosullari",
            component: () => import("@/components/sayfalar/iys/ApiLisans"),
            meta: {
              title: "Api Lisans Koşulları"
            }
          },
          {
            path: "hakkimizda",
            component: () => import("@/components/sayfalar/iys/Hakkimizda"),
            meta: {
              title: "İleti Yönetim Sistemi A.Ş. Yönetim Beyanı"
            }
          },
          {
            path: "duyurular",
            component: () => import("@/components/sayfalar/iys/Duyurular"),
            meta: {
              title: "Duyurular"
            }
          },
          {
            path: "sss",
            component: () => import("@/components/sayfalar/iys/SSS"),
            meta: {
              title: "SSS"
            }
          }
          // {
          //   path: "faaliyet-takvimi",
          //   component: () =>
          //     import("@/components/sayfalar/iys/Faaliyet-Takvimi"),
          //   meta: {
          //     title: "Faaliyet Takvimi"
          //   }
          // },
          // {
          //   path: "paketler",
          //   component: () => import("@/components/sayfalar/iys/Paketler"),
          //   meta: {
          //     title: "Paketler"
          //   }
          // }
        ]
      },

      // {
      //   path: "vatandas",
      //   component: () => import("@/pages/Vatandaslar"),
      //   meta: { siteMapHide: true },
      //   children: [
      //     // {
      //     //   path: "nasil",
      //     //   component: () => import("@/components/sayfalar/vatandaslar/Nasil"),
      //     //   meta: {
      //     //     title: "Vatandaş Nasıl"
      //     //   }
      //     // }
      //     // {
      //     //   path: "izin-yonetimi",
      //     //   component: () =>
      //     //     import("@/components/sayfalar/vatandaslar/Izin-Yonetimi"),
      //     //   meta: {
      //     //     title: "Vatandaş İzin Yönetimi"
      //     //   }
      //     // },
      //     // {
      //     //   path: "sikayet-sistemi",
      //     //   component: () =>
      //     //     import("@/components/sayfalar/vatandaslar/Sikayet-Sistemi"),
      //     //   meta: {
      //     //     title: "Vatandaş Şikayet Sistemi"
      //     //   }
      //     // }
      //   ]
      // },

      {
        path: "iletisim",
        component: () => import("@/components/sayfalar/Iletisim"),
        meta: {
          title: "İletişim"
        }
      },
      {
        path: "api-basvuru-formu",
        component: () => import("@/components/sayfalar/ApiBasvuruFormu"),
        meta: {
          title: "İletişim"
        }
      }
    ]
  },

  {
    path: "*",
    name: "404",
    component: () => import("@/pages/Error404")
  }
];
