import Capcha from "../components/genel/Capcha";
import Email from "../components/genel/Email";
import FaturaAdresi from "../components/genel/FaturaAdresi";
import Paket from "../components/genel/Paket";
import TabsBasvuru from "../components/genel/TabsBasvuru";
import TabsBasvuruSub from "../components/genel/TabsBasvuruSub";

import TabsNewBasvuru from "../components/genel/TabsNewBasvuru";
import TabsGirisBasvuruSub from "../components/genel/TabsGirisBasvuruSub";
import TabsEimzaBasvuruSub from "../components/genel/TabsEimzaBasvuruSub";
import TabsEdevletBasvuruSub from "../components/genel/TabsEdevletBasvuruSub";

import TabsPaket from "../components/genel/TabsPaket";
import UserAddress from "../components/genel/UserAddress";
import HizmetSozlesmesi from "../components/genel/HizmetSozlesmesi";
import TaahhutnameBasvuru from "../components/genel/TaahhutnameBasvuru";
import TaahhutnameBasvuruNoMersis from "../components/genel/TaahhutnameBasvuruNoMersis";
import TaahhutnameNoMersis from "../components/genel/TaahhutnameNoMersis";
import HizmetSozlesmesiKabul from "../components/hs/basvuru/genel/HizmetSozlesmesiKabul";
import AydinlatmaMetniKabul from "../components/hs/basvuru/genel/AydinlatmaMetniKabul";
import KullanimTaahhutnamesiKabul from "../components/hs/basvuru/genel/KullanimTaahhutnamesiKabul";
import AydinlatmaMetni from "../components/genel/AydinlatmaMetni";
import AydinlatmaKurumsal from "../components/genel/AydinlatmaKurumsal";
import AydinlatmaVatandas from "../components/genel/AydinlatmaVatandas";
import AydinlatmaIletisim from "../components/genel/AydinlatmaIletisim";
import KullanimTaahhutnamesi from "../components/genel/KullanimTaahhutnamesi";

import ToolTip from "../components/genel/ToolTip";
import ToolTipHs from "../components/genel/ToolTipHs";
import BasvuruNoBox from "../components/genel/BasvuruNoBox";

import IysInput from "../components/genel/IysInput";
import MainImage from "../components/sayfalar/MainImage";

const IYSComponents = {
  install(Vue, options) {
    Vue.component(Capcha.name, Capcha);
    Vue.component(Email.name, Email);
    Vue.component(FaturaAdresi.name, FaturaAdresi);
    Vue.component(Paket.name, Paket);
    Vue.component(TabsBasvuru.name, TabsBasvuru);
    Vue.component(TabsBasvuruSub.name, TabsBasvuruSub);
    Vue.component(TabsPaket.name, TabsPaket);
    Vue.component(UserAddress.name, UserAddress);
    Vue.component(HizmetSozlesmesi.name, HizmetSozlesmesi);
    Vue.component(HizmetSozlesmesiKabul.name, HizmetSozlesmesiKabul);
    Vue.component(AydinlatmaMetniKabul.name, AydinlatmaMetniKabul);
    Vue.component(KullanimTaahhutnamesiKabul.name, KullanimTaahhutnamesiKabul);
    Vue.component(TaahhutnameBasvuru.name, TaahhutnameBasvuru);
    Vue.component(TaahhutnameBasvuruNoMersis.name, TaahhutnameBasvuruNoMersis);
    Vue.component(TaahhutnameNoMersis.name, TaahhutnameNoMersis);
    Vue.component(AydinlatmaMetni.name, AydinlatmaMetni);
    Vue.component(AydinlatmaKurumsal.name, AydinlatmaKurumsal);
    Vue.component(AydinlatmaVatandas.name, AydinlatmaVatandas);
    Vue.component(AydinlatmaIletisim.name, AydinlatmaIletisim);
    Vue.component(KullanimTaahhutnamesi.name, KullanimTaahhutnamesi);

    Vue.component(ToolTip.name, ToolTip);
    Vue.component(ToolTipHs.name, ToolTipHs);
    Vue.component(BasvuruNoBox.name, BasvuruNoBox);

    Vue.component(IysInput.name, IysInput);
    Vue.component(MainImage.name, MainImage);

    Vue.component(TabsNewBasvuru.name, TabsNewBasvuru);
    Vue.component(TabsGirisBasvuruSub.name, TabsGirisBasvuruSub);
    Vue.component(TabsEimzaBasvuruSub.name, TabsEimzaBasvuruSub);
    Vue.component(TabsEdevletBasvuruSub.name, TabsEdevletBasvuruSub);
  }
};

export default IYSComponents;
