import Vue from "vue";
import Router from "vue-router";

import app_module from "../store/modules/app";
import applicant_module from "../store/modules/applicant";

var DEV = process.env.VUE_APP_ENV !== "production";

Vue.use(Router);

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = function(to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition;
  } else {
    const position = {};

    // scroll to anchor by returning the selector
    if (to.hash) {
      position.selector = to.hash;

      // specify offset of the element
      if (to.hash === "#anchor2") {
        position.offset = { y: 100 };
      }

      // bypass #1number check
      if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
        return position;
      }

      // if the returned position is falsy or an empty object,
      // will retain current scroll position.
      return false;
    }

    return new Promise(resolve => {
      // check if any matched route config has meta that requires scrolling to top
      if (to.matched.some(m => m.meta.scrollToTop)) {
        // coords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.x = 0;
        position.y = 0;
      }

      // wait for the out transition to complete (if necessary)
      this.app.$root.$once("triggerScroll", () => {
        // if the resolved position is falsy or an empty object,
        // will retain current scroll position.
        resolve(position);
      });
    });
  }
};

const router = new Router({
  mode: "history",
  scrollBehavior: () => ({ x: 0, y: 0 }),
  // scrollBehavior (to, from, savedPosition) {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve({ x: 0, y: 0 })
  //     }, 500)
  //   })
  // },
  // scrollBehavior(to, from, savedPosition) {
  //   savedPosition ? savedPosition : { x: 0, y: 0 };
  // },
  // scrollBehavior,
  routes: require("./routes")
});

router.onError(error => {
  // No access to `to`, `from`, `next`
  console.log("ROUTER ERROR:", error);
  applicant_module.state.vm.ConnectionError();
});

const mainTitle = "İleti Yönetim Sistemi";
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // window.scrollTo(0, 0)

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle)
    document.title = mainTitle + " - " + nearestWithTitle.meta.title;
  if (!nearestWithTitle) document.title = mainTitle;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

router.beforeEach((to, from, next) => {
  const pathMap = {
    "/hizmet-saglayici/paketler-ve-moduller":
      "/hizmet-saglayici/kurumsal-hizmetler",
    "/vatandaslar": "/vatandas-hizmetleri",
    "/iys/nasil-calisir": "/hizmetlerimiz",
    "/hizmet-saglayicilar": "/hizmet-saglayici/kurumsal-hizmetler"
  };
  const redirectTo = pathMap[to.path];
  if (DEV) {
    console.log("redirectTo:", redirectTo);
  }
  if (redirectTo) {
    return next(redirectTo);
  }
  return next();
});

router.beforeResolve((to, from, next) => {
  if (DEV) {
    console.log(
      "--- ROUTE CHANGE (BEFORE RESOLVE) ---",
      "\nfrom",
      from.path,
      "\nto:",
      to.path,
      "\nfromMeta",
      from.meta,
      "\ntoMeta",
      to.meta
    );
  }

  if (to.name === "404") return next();

  if (to.path === "/") app_module.state.show_basvuru = true;

  if (to.meta.hide_basvuru) {
    app_module.state.show_basvuru = false;
  } else {
    app_module.state.show_basvuru = true;
  }

  if (to.meta.toNotFound) {
    return next("/not-found");
  }

  if (to.meta.reset_basvuru) {
    applicant_module.mutations.RESET_APPNO(applicant_module.state);
  }

  // Başvuru no gereken bir path de başvuru no yoksa başa dön
  if (to.meta.requires_appNo && !applicant_module.state.application.appNo)
    return next("/");

  if (
    to.meta.requires_RESERVED &&
    !(applicant_module.state.application.step === "RESERVED")
  )
    return next("/");

  if (
    to.meta.requires_INITIAL_AGENT_CONFIRMED &&
    !(
      applicant_module.state.application.step === "INITIAL_AGENT_CONFIRMED" ||
      applicant_module.state.application.step === "FINAL_AGENT_REJECTED"
    )
  )
    return next("/");

  if (
    to.meta.requires_INITIAL &&
    !(applicant_module.state.application.step.substring(0, 7) === "INITIAL")
  )
    return next("/");

  next();
});

export default router;
